<template>
    <v-dialog persistent v-model="dialog" max-width="1000px">
        <v-card :loading="loading">
            <v-card-title class="pa-0">
                <v-toolbar dark color="primary">
                    <v-toolbar-title>{{
                        $t("preview") +
                            " " +
                            $t("alerts.ui.multipleDestinations")
                    }}</v-toolbar-title>
                </v-toolbar>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" >
                            <v-text-field
                                dense
                                outlined
                                :loading="loading"
                                v-model="search"
                                append-icon="mdi-magnify"
                                :label="$t('search')"
                                single-line
                                hide-details
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col  class="py-0"
                            v-if="
                                departments != null && departments.length != 0
                            "
                            cols="12"
                        >
                            <v-data-table
                                :headers="departmentHeaders"
                                :items="departments"
                                :items-per-page="5"
                                :search="search"
                            ></v-data-table>
                        </v-col>
                        <v-col class="py-0"
                            v-if="positions != null && positions.length != 0"
                            cols="12"
                        >
                            <v-data-table
                                :headers="positionsHeaders"
                                :items="positions"
                                :items-per-page="-1"
                                hide-default-footer
                                
                                
                            ></v-data-table>
                        </v-col>
                        <v-col  class="py-0"
                            v-if="employees != null && employees.length != 0"
                            cols="12"
                        >
                            <v-data-table
                                :headers="employeeHeaders"
                                :items="employees"
                                :items-per-page="5"
                                :search="search"
                            ></v-data-table>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="close" color="red" dark
                    >{{ $t("close") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import axios from "axios";

export default {
    props: ["dialog", "close", "alertGuid"],
    data() {
        return {
            departments: [],
            employees: [],
            positions: [],
            search: "",
            loading: false,
            departmentHeaders: [
                {
                    text: this.$t("departments.departmentName"),
                    value: "departmentName"
                }
            ],
            employeeHeaders: [
                {
                    text: this.$t("employees.employeeCode"),
                    value: "employeeCode"
                },
                {
                    text: this.$t("employees.employeeName"),
                    value: "employeeName"
                },
                {
                    text: this.$t("departments.departmentName"),
                    value: "departmentName"
                }
            ],
            positionsHeaders: [
                {
                    text: this.$t("positionTypes.position"),
                    value: "toType"
                }
            ]
        };
    },
    created() {
        try {
            if (this.alertGuid == undefined || this.alertGuid == null) return;
            this.loading = true;
            axios
                .get("Alerts/GetAlertDestinations?id=" + this.alertGuid)
                .then(response => {
                    this.departments = response.data.data.departments;
                    this.employees = response.data.data.employees;
                    this.positions = response.data.data.positions;
                    this.positions.forEach(m => {
                        m.toType = this.$t("positionTypes." + m.toType);
                    });
                })
                .catch(e => {
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false;
                });
        } catch (error) {
            console.log(error);
        }
    }
};
</script>
